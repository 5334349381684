<template>
  <div class="page">
    <el-result icon="info" subTitle="您访问的链接/页面不可用，请返回重试" title="当前页面走丢了">
      <template slot="extra">
        <a href="/">返回首页</a>
      </template>
    </el-result>
  </div>

</template>

<script>
export default {
  name: "not-found"
}
</script>

<style scoped>
.page {
  background-color: #eeeeee;
  padding: 3.5rem 0;
}
</style>
